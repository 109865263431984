"use strict";

var _interopRequireDefault = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAppFixpoint = addAppFixpoint;
exports.dataManipulation = dataManipulation;
exports.getAppFixpointList = getAppFixpointList;
exports.getAppNameList = getAppNameList;
exports.getAppVersionList = getAppVersionList;
exports.getLanList = getLanList;
exports.searchAppFixpoint = searchAppFixpoint;
exports.updateAppFixpoint = updateAppFixpoint;

var _request = _interopRequireDefault(require("@/utils/request"));

//查询app修复点列表
function getAppFixpointList(data) {
  return (0, _request.default)({
    url: 'system/business/app/fixpoint/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //查询语言列表


function getLanList(data) {
  return (0, _request.default)({
    url: 'system/base/dictionary/param',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //查询app名称列表


function getAppNameList() {
  return (0, _request.default)({
    url: 'system/business/app/version/name',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //查询app版本列表


function getAppVersionList(data) {
  return (0, _request.default)({
    url: 'system/business/app/version/number',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //新增app修复点信息


function addAppFixpoint(data) {
  return (0, _request.default)({
    url: 'system/business/app/fixpoint/save',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //修改app修复点信息


function updateAppFixpoint(data, id) {
  return (0, _request.default)({
    url: 'system/business/app/fixpoint/update/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //查询单个修复点信息


function searchAppFixpoint(id) {
  return (0, _request.default)({
    url: 'system/business/app/fixpoint/query/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
/**
 * 前端刷新公共方法
 */


function dataManipulation(tableData, rowData, fixPoint, opTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == rowData.id) {
      if (name != null && name != "") {
        element.name = name;
      }

      if (fixPoint != null && fixPoint != "") {
        element.fixPoint = fixPoint;
      }

      element.opUser = window.localStorage.getItem("username");

      if (opTime != null && opTime != "") {
        element.opTime = opTime;
      }
    }
  });
  return tableData;
}